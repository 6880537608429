<template>
  <div>
    <div
      v-if="activities.length && isValidRange"
      class="gant-chart">
      <div class="gant-chart__aside">
        <div class="date-nav">
          <button
            class="date-nav__btn"
            title="Предыдущий день"
            @click.prevent="nextDate(-1)">
            <BaseIcon glyph="arrow-left" />
          </button>
          <button
            class="date-nav__btn date-nav__btn--main"
            title="Сегодня"
            @click.prevent="goToDate()">
            <span class="date-nav__tooday-text">Сегодня</span>
            <span class="date-nav__tooday-icon">
              <BaseIcon glyph="calendar" />
            </span>
          </button>
          <button
            class="date-nav__btn"
            title="Следующий день"
            @click.prevent="nextDate(1)">
            <BaseIcon glyph="arrow-right" />
          </button>
        </div>
        <div class="gant-chart__act">
          <div
            v-for="item in mappedActivity"
            :key="item.id"
            :class="{ 'is-active': activeActivity === item.id }"
            class="gant-chart__act-item"
            @click="handleTitleClick(item.id)">
            <span class="gant-chart__act-title">{{ item.title }}</span>
            <span class="gant-chart__act-title-mobile">{{
              item.title_short
            }}</span>
          </div>
        </div>
      </div>
      <div class="gant-chart__calendar">
        <div
          ref="scroller"
          class="calendar-wrapper">
          <div
            class="calendar-inner"
            :style="{ width: gridSize + 'px' }">
            <div class="calendar">
              <div
                v-for="month in calendar"
                :key="month.name"
                class="calendar__month">
                <div class="calendar__month-name">
                  <span>{{ month.name }}</span>
                </div>
                <div class="calendar__days">
                  <div
                    v-for="(day, idx) in month.days"
                    :key="idx"
                    class="calendar__day-column"
                    :style="{ width: dayWidth + 'px' }"
                    :class="{
                      weekend: day.weekend,
                      selected: day.formatedDate === highLightDay,
                      'month-start': day.date === 1,
                    }">
                    <div class="calendar__days-title">
                      <span>{{ day.day }}</span> <span>{{ day.date }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="task-wrapper">
              <div
                v-for="activity in mappedAttempts"
                :key="activity.id"
                class="task-row">
                <div
                  v-for="(item, attemptIdx) in activity.attempts"
                  :key="item.id"
                  class="task"
                  :class="[`task--${item.status}`]"
                  :title="`${activity.title}: ${item.title}`"
                  :style="{
                    left: item._left,
                    width: item._width,
                  }">
                  <div
                    class="task__inner"
                    @click="handleOpenAttempt(item)">
                    <div class="task__count">
                      {{ attemptIdx + 1 }}
                    </div>
                    <div>
                      <div class="task__start">{{ item.formated_start }}</div>
                      <div class="task__end">{{ item.formated_end }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="activities.length && !isValidRange">
      <p class="mt-s mb-s">Нет данных по выбранным параметрам</p>
    </div>
    <div v-else>
      <p class="mt-s mb-s">Данные по расписанию отсутствуют</p>
    </div>
  </div>
</template>

<script>
import dayjs from "@/plugins/dayjs";
import { delay } from "@/utils";
import debounce from "lodash/debounce";
import AttemptModal from "@/modals/AttemptModal";

const attemptMapper = (attempt, min, now, dayWidth) => {
  let status = "";
  const startDay = dayjs(attempt.start_at);
  const endDay = dayjs(attempt.end_at);
  if (+startDay > now) {
    status = "upcoming";
  } else if (+startDay < now && +endDay > now) {
    status = "current";
  } else {
    status = "past";
  }
  return {
    ...attempt,
    _left: startDay.diff(min, "days") * dayWidth + "px",
    _width:
      (dayjs(attempt.end_at).diff(startDay, "days") + 1) * dayWidth + "px",
    formated_start: dayjs(attempt.start_at).format("DD.MM HH:mm"),
    formated_end: dayjs(attempt.end_at).format("DD.MM HH:mm"),
    status,
  };
};

export default {
  name: "ScheduleGrid",
  props: {
    activities: Array,
  },
  data() {
    return {
      dayWidth: 54,
      highLightDay: null,
      activeActivity: null,
    };
  },
  computed: {
    isValidRange() {
      const { dateRange } = this;
      return dateRange && dateRange.min > 0 && dateRange.max > 0;
    },
    dateRange() {
      const { activities } = this;
      const result = activities.reduce(
        (range, activity) => {
          activity.attempts.forEach((attempt) => {
            const start = +dayjs(attempt.start_at);
            const end = +dayjs(attempt.end_at);
            // Первая итерация, просто проставляем значение
            if (range.min === 0) {
              range.min = start;
              range.max = end;
            } else {
              range.min = Math.min(range.min, start);
              range.max = Math.max(range.max, end);
            }
          });
          return range;
        },
        { min: 0, max: 0, total: 0 }
      );

      // Наверное, раз разбивка идет по дням,
      // то лучше начинать с понедельника и заканчивать
      // воскресеньем. Иначе -- странно

      if (result.min === 0 && result.max === 0) {
        return result;
      }

      // получаем день недели min 0-6, 0 = вскр
      const minDay = dayjs(result.min).get("day");
      // если не понедельник - то достроим до понедельника
      if (minDay !== 1) {
        result.min = +dayjs(result.min).subtract(
          minDay === 0 ? 6 : minDay - 1,
          "day"
        );
      }
      const maxDay = dayjs(result.max).get("day");
      // если не воскресенье - то достроим до воскресенья
      if (maxDay !== 0) {
        result.max = +dayjs(result.max).add(7 - maxDay, "day");
      }

      // Выставляю минимальную дату в начало дня,
      // чтобы правильно считать смещение
      result.min = dayjs(result.min).set("hour", 0).set("minute", 0);
      result.max = dayjs(result.max).set("hour", 0).set("minute", 0);
      result.total = dayjs(result.max).diff(result.min, "day");
      return result;
    },
    calendar() {
      const { dateRange } = this;
      const min = dayjs(dateRange.min);
      const diff = dateRange.total;
      const days = {};
      // Создаю массив с месяцами и днями
      for (let i = 0; i <= diff; i++) {
        const date = dayjs(min).add(i, "day");
        const year = date.get("year");
        const month = date.get("month");
        const item = {
          date: date.get("date"),
          day: date.format("dd"),
          weekend: [0, 6].includes(date.get("day")),
          formatedDate: date.format("DD:MM:YYYY"),
          formatedTime: date.format("HH:mm"),
        };

        if (days[year]) {
          if (days[year][month]) {
            days[year][month].days.push(item);
          } else {
            days[year][month] = {
              name: date.format("MMMM YYYY"),
              days: [item],
            };
          }
        } else {
          days[year] = [];
          days[year][month] = {
            name: date.format("MMMM YYYY"),
            days: [item],
          };
        }
      }
      return Object.values(days).reduce((acc, val) => {
        const cMonths = val.filter(Boolean);
        return [...acc, ...cMonths];
      }, []);
    },
    mappedActivity() {
      // разбираю названия активностей
      // чтобы на выходе получалось
      // из Математика 8-11 => М 8-11
      // иначе плохо будут влезать на мобилке
      const classRe = /^\d{1,2}$|^\d{1,2}-\d{1,2}/;
      return this.activities.map((n) => {
        const titles = n.title
          .split(" ")
          .map((n) => {
            if (classRe.test(n)) {
              return ` ${n}`;
            }
            return n[0];
          })
          .join("")
          .toUpperCase();

        return {
          ...n,
          title_short: titles,
        };
      });
    },
    mappedAttempts() {
      const min = dayjs(this.dateRange.min);
      const now = Date.now();
      return this.mappedActivity.map((n) => {
        let attempts = [];
        if (n.attempts?.length) {
          attempts = n.attempts.map((attempt) => {
            return attemptMapper(attempt, min, now, this.dayWidth);
          });
        }
        return {
          ...n,
          attempts,
        };
      });
    },
    gridSize() {
      // +1 потому, что от 0
      return this.dayWidth * (this.dateRange.total + 1);
    },
  },
  mounted() {
    // this.goToDate();
    this.bindMouseWheel();
  },
  methods: {
    bindMouseWheel() {
      const scroller = this.$refs.scroller;
      if (!scroller) return;
      scroller.addEventListener(
        "mousewheel",
        debounce(this.mouseWheelHandler, 250),
        { passive: true }
      );
    },
    mouseWheelHandler(e) {
      const scroller = this.$refs.scroller;
      if (!scroller) return true;
      const maxScrollLeft = scroller.scrollWidth - scroller.clientWidth;
      const delta = e.deltaY * 0.75;
      if (
        (delta > 0 && scroller.scrollLeft < maxScrollLeft) ||
        (delta < 0 && scroller.scrollLeft !== 0)
      ) {
        scroller.scrollLeft = scroller.scrollLeft + delta;
      }
    },
    async goToDate(date) {
      const { dateRange, dayWidth } = this;
      const pDate = dayjs(date ? date : Date.now());
      // Если дата больше максимальной, то пролистаем в конец
      if (+pDate >= dateRange.max) {
        this.gridScroll(dateRange.total * dayWidth);
        return;
      }
      // если меньше, то игнорим
      if (+pDate < dateRange.min) {
        this.gridScroll(0);
        return;
      }
      const diffDays = pDate.diff(dateRange.min, "days");
      this.gridScroll(diffDays * dayWidth);
      this.highLightDay = pDate.format("DD:MM:YYYY");
      await delay(2000);
      this.highLightDay = null;
    },
    nextDate(direction = 1) {
      const { dayWidth } = this;
      const scroll = this.$refs.scroller;
      if (!scroll) return;
      const fullScrolledDays = Math.ceil(scroll.scrollLeft / dayWidth);
      const newScrollPosition = (fullScrolledDays + direction) * dayWidth;
      this.gridScroll(newScrollPosition);
    },
    gridScroll(position) {
      const scroll = this.$refs.scroller;
      if (!scroll) return;
      scroll.scrollLeft = position;
    },
    handleOpenAttempt(attempt) {
      if (!attempt || !attempt?.activity_id) return;
      const activity = this.activities.find(
        (n) => n.id === attempt.activity_id
      );
      if (!activity) return;
      this.$modal.show(
        AttemptModal,
        {
          attempt,
          activity,
        },
        {
          adaptive: true,
          height: "auto",
          scrollable: true,
          minHeight: 200,
        }
      );
    },
    async handleTitleClick(id) {
      if (this.activeActivity === id) {
        this.activeActivity = null;
        return;
      }
      this.activeActivity = id;
      await delay(1500);
      if (this.activeActivity === id) {
        this.activeActivity = null;
      }
    },
  },
};
</script>

<style lang="less" scoped>
@grid-border-color: fade(@grey-blue, 20%);
@grid-selected-color: darken(@bg-light, 5%);
@grid-weekend-color: darken(@bg-light, 2%);
.gant-chart {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  width: 100%;
  align-items: stretch;

  &__aside {
    width: 145px;
    margin-right: 17px;
    flex-grow: 0;
    flex-shrink: 0;
    min-height: 1px;
    padding-bottom: 17px; // + scroll height
    padding-bottom: var(--scrollbar-width); // + scroll height

    .media-only-xs({
      width: 80px;
      margin-right: 5px;
    });
  }
  &__calendar {
    min-width: 0;
    flex-grow: 1;
  }

  &__act-title {
    display: inline-block;
    overflow: hidden;
    max-height: 100%;
    text-overflow: ellipsis;
    .media-only-xs({
      position: absolute;
      top: 50%;
      max-width: 180px;
      min-width: 120px;
      display: block;
      background-color: @primary-color;
      color: #fff;
      left: 100%;
      transform: translate(-30px, -50%);
      z-index: 1;
      pointer-events: none;
      padding: 3px 6px;
      font-size: 11px;
      box-shadow: 5px 0px 4px -2px fade(@primary-color, 10%);
      pointer-events: none;
      border-radius: @radius-s;
      margin-left: 5px;
      opacity: 0;
      transition: opacity 0.3s, transform 0.3s;

      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        margin-top: -6px;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid @primary-color;
        left: -6px;
      }
    });
  }

  &__act-title-mobile {
    display: none;

    .media-only-xs({
      display: block;
    });
  }

  &__act-item {
    height: 60px;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
    text-align: center;
    justify-content: center;
    padding-left: 5px;
    padding-right: 5px;
    font-size: 14px;
    border-top: 1px solid @grid-border-color;
    overflow: hidden;
    user-select: none;
    position: relative;

    &:first-child {
      border-top: 0;
    }

    & > span {
      word-break: break-all;
    }

    .media-only-xs({
      overflow: visible;

      &.is-active {
        .gant-chart__act-title {
          opacity: 1;
          transform: translate(0, -50%)
        }
      }
    });
  }
}
.calendar {
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  height: 100%;

  &-inner {
    position: relative;
    height: 100%;
  }

  &-wrapper {
    overflow: auto;
    position: relative;
    height: 100%;
    min-height: 50px;
    scroll-behavior: smooth;
    scroll-snap-type: x proximity;
    cursor: ew-resize;
  }
  &__days {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-grow: 1;
    user-select: none;

    &-title {
      padding: 8px 0;
      height: 30px;
      text-transform: capitalize;
    }
  }
  &__day-column {
    flex-grow: 0;
    flex-shrink: 0;
    border-left: 1px solid @grid-border-color;
    text-align: center;
    font-size: 12px;
    transition: background 0.3s;
    scroll-snap-align: start;
    user-select: none;

    &.month-start {
      box-shadow: -1px 0px 0px fade(@grey-blue, 30%);
      border-left-color: fade(@grey-blue, 30%);
    }

    &.weekend {
      background-color: @grid-weekend-color;
    }

    &.selected {
      background-color: @grid-weekend-color;
    }
  }
  &__month {
    display: flex;
    flex-direction: column;
    color: @grey-blue;
    font-size: 12px;
    user-select: none;

    &-name {
      padding-left: 5px;
      padding-right: 5px;
      height: 30px;
      display: flex;
      align-items: center;
      span {
        left: 5px;
        position: sticky;
        flex-grow: 0;
      }
    }
  }
}
.task {
  position: absolute;
  height: 100%;
  top: 0;
  display: flex;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 0;
  padding-left: 10px;
  padding-right: 10px;
  user-select: none;

  &-row {
    position: relative;
    width: 100%;
    left: 0;
    height: 60px;
    border-top: 1px solid @grid-border-color;
    transition: background-color 0.3s;

    &:hover {
      background-color: fade(@grid-border-color, 10%);
    }
  }
  &-wrapper {
    position: absolute;
    left: 0;
    bottom: 0;
    top: 65px;
    width: 100%;
  }

  &__start,
  &__end {
    text-align: center;
  }

  &__inner {
    background-color: #fff;
    padding: 6px 5px;
    font-size: 12px;
    box-shadow: 0px 6.66667px 6.66667px rgba(207, 216, 250, 0.48);
    border-radius: 3px;
    border: 0.5px solid #4d72b8;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
    line-height: 1.2;
    width: 100%;
    cursor: pointer;
  }

  &__count {
    width: 14px;
    height: 14px;
    background-color: @primary-color;
    text-align: center;
    line-height: 14px;
    border-radius: 50%;
    color: #fff;
    margin-right: 0.4em;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 12px;
  }

  &--current &__count {
    background-color: @success-color;
  }

  &--upcoming &__count {
    background-color: @link-blue;
  }
}

.date-nav {
  margin-top: 30px;
  margin-bottom: 5px;
  display: flex;
  font-family: row nowrap;
  justify-content: stretch;
  background-color: #fff;
  border-radius: @radius-s;
  overflow: hidden;

  &__btn {
    height: 30px;
    background-color: transparent;
    border: 0;
    padding: 0;
    text-align: center;
    min-width: 32px;
    color: @grey-blue;
    cursor: pointer;
    transition: background 0.3s, color 0.3s;

    &:hover {
      background-color: @grey-blue;
      color: #fff;
    }

    &--main {
      flex-grow: 1;
    }

    .media-only-xs({
      min-width: 32%;
    });
  }

  &__tooday-text {
    .media-only-xs({
      display: none;
    });
  }
  &__tooday-icon {
    display: none;
    .media-only-xs({
      display: block;
    });
  }
}
</style>
