<template>
  <div class="stage-tabs">
    <button
      class="stage-tabs__item"
      :class="{ 'is-active': value === 1 }"
      @click.prevent="handleClick(1)">
      Этап 1
    </button>
    <button
      class="stage-tabs__item"
      :class="{ 'is-active': value === 2 }"
      @click.prevent="handleClick(2)">
      Этап 2
    </button>
  </div>
</template>

<script>
/**
 * Переключалка этапов 1 - 2
 * !Warn не менять значения!
 */
export default {
  name: "StageTabs",
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  methods: {
    handleClick(value) {
      if (value !== this.stage) {
        this.$emit("change", value);
        this.$emit("input", value);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.stage-tabs {
  display: inline-block;
  border: 1px solid @primary-color;
  border-radius: @radius-xs;
  &__item {
    padding: 8px 20px;
    background-color: transparent;
    border: 0;
    box-shadow: none;
    font-family: inherit;
    border-radius: 0;
    color: @primary-color;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    transition: background-color 0.3s, color 0.3s;

    & + & {
      border-left: 0;
    }

    &:hover {
      background-color: fade(@primary-color, 5%);
    }

    &.is-active {
      background-color: @primary-color;
      color: #fff;
      cursor: default;

      &:hover {
        background-color: @primary-color;
      }
    }
  }
}
</style>
